import React from 'react';
import styled from 'styled-components';

import { PageTitle, PageWrapper } from '../../components/pageComponents';
import { JoinUs, JoinInformation } from '../../components';
import { BrideForm } from '../../containers';
import theme from '../../styles/theme';

const GrayLine = styled.div`
  background-color: ${theme.colors.grey2};
  height: 1px;
  width: 85%;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

const JoinWrapper = styled.div`
  width: 85%;
`;

const PageSubtitle = styled.p`
  font-weight: bold;
  margin-bottom: 2em;
`;

const StyledPageTitle = styled(PageTitle)`
  margin: 0;
`;

const JoinBride = () => {
  return (
    <PageWrapper>
      <StyledPageTitle>FAÇA PARTE</StyledPageTitle>
      <PageSubtitle>NOIVO(A)</PageSubtitle>
      <BrideForm />
      <JoinInformation />
      <GrayLine />
      <JoinWrapper>
        <JoinUs />
      </JoinWrapper>
    </PageWrapper>
  );
};

export default JoinBride;
